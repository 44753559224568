<template>
  <Title title="Auto-évaluation du début" :showBack="true" :showIcon="true"/>
  <div class="text-center wellbeing-form">
    <div>
      <p>Vous allez être accompagné par un coach sur le sujet suivant :</p>
      <p>{{ boostPack.description }}</p>
    </div>
    <p class="nota-bene">NB : Les notes ne seront pas communiquées à votre entreprise.</p>
    <Criterion :displayNB="true" :editable="true" :criterias="criterias"/>
    <button class="btn-pink" @click="createBoostPackCriterion">Enregistrer</button>
  </div>
</template>

<script>
import boostPackService from "../../services/boostPack.service";
import Criterion from "../../components/BoostPack/Criterion";
import Title from "../../components/General/Title";
import BoostPackService from "../../services/boostPack.service";
import Toastify from "toastify-js";

export default {
  name: "BoostPackCriterion",
  components: {Criterion, Title},
  data() {
    return {
      boostPack: Object,
      criterias: []
    };
  },
  created() {
    boostPackService.getCurrentBoostPack().then((response) => {
      this.boostPack = response.data.data.boost_pack
      if (this.boostPack.boost_consumption === 0) {
        this.criterias = this.boostPack.criteria
      } else {
        this.$router.push({name: 'BoostPackMeeting'})
      }
    })
  },
  methods: {
    createBoostPackCriterion() {
      let criteriasResults = this.criterias.filter(x => x.score !== null);

      BoostPackService.createBoostPackCriterionRate(this.boostPack.id, criteriasResults).then(() => {
        Toastify({
          text: 'Baromètre bien-être sauvegardé',
          escapeMarkup: false,
          className: "success",
        }).showToast();
        this.$router.push({name: 'BoostPackMeeting'})
      });
    }
  },
}
</script>

<style scoped>

</style>
